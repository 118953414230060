import React, { Component} from "react";
import "./App.css";

import DisplayMap from "./DisplayMap.js";

class App extends Component{
  render(){
    return(
      <div className="App">
        <h1>Map Sandbox</h1>
        <DisplayMap />
      </div>
    );
  }
}

export default App;
